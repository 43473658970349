export const data =[
    {
        quote:"Di blogger ini saya mencatat segala sesuatu yang sudah saya lakukan, segala sesutu yang sudah saya pelajari"
    },
    {
        quote:"NEVER GIVE UP!"
    },
    {
        quote:"Never regret a day in your life. Good days bring you happiness and bad days give you experience"
    },
    {
        quote:"Time is what we want most and what we use worst"
    },
    {
        quote:"Everyone thinks of changing the world, but no one thinks of changing himself"
    },
    {
        quote:"Happiness is not something ready made. It comes from your own actions"
    },
    {
        quote:"You can either experience the pain of discipline or the pain of regret. The choice is yours"
    },
    {
        quote:"Words can inspire, thoughts can provoke, but only action truly brings you closer to your dreams"
    },
    {
        quote:"Do something today that your future self will thank you for"
    },
    {
        quote:"Be strong enough to let go and wise enough to wait for what you deserve"
    },
    {
        quote:"Whatever you are, be a good one"
    }
]