export const Data = [
    {
       role: "1",
       data: [
           {
               name: "Form",
               route: "/form"
           },
           {
               name: "Profile",
               route: "/profile"
           },
           {
               name:"Report",
               route:"/report"
           },
           {
                name:"Music",
                route:"/music"
           },
           {
            name:"Music Remote",
            route:"/musicremote"
           }
       ]
    },
    {
        role:"2",
        data:[
            {
                name: "Profile",
                route: "/profile"
            },
            {
                 name:"Music",
                 route:"/music"
            }
        ]
    }
]